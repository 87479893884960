import axios from '../../Api/axios';
import Api from '../../Api';
export const authActions = {
  AUTH_START: 'AUTH_START',
  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGOUT: 'AUTH_LOGOUT'
}

const authStart = () => (
  {
    type: authActions.AUTH_START
  }
)

const authError = (err) => (
  {
    type: authActions.AUTH_ERROR,
    payload: err
  }
)

const onLoginSuccess = ({ token, email }) => {
  Api.authenticate(token);

  return {
    type: authActions.AUTH_LOGIN_SUCCESS,
    payload: { token, email }
  }
}

export const onLogin = (email, password) => async dispatch => {
    dispatch(authStart());

    const auth = {
        email,
        password,
        strategy: 'local'
    };

    try {
      const response = await axios.post('/authentication', auth);
      const token = response.data.accessToken;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('useremail', email);
      dispatch(onLoginSuccess({ token, email }));
    } catch (error) {
      dispatch(authError(error.response.data));
    }
}

export const validateAuthToken = () => dispatch => {
  const token = window.localStorage.getItem('accessToken');
  const email = window.localStorage.getItem('useremail');

  dispatch(onLoginSuccess({ token, email }))
}

export const onLogout = () => dispatch => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('useremail');
  Api.authenticate(undefined);
  dispatch({
    type: authActions.AUTH_LOGOUT
  })
}

import { productActions } from './actions';

const initialState = {
  loading: false,
  error: null,
  products: []
};

const adaptProductChange = (state, newProduct) => {
  const newProducts = Object.assign([], state.products);
  const productIndex = newProducts.findIndex(item => item.id === newProduct.id);
  newProducts[productIndex] = newProduct;
  return { ...state, products: newProducts, error: null, loading: false }
}

const reducer = (state = initialState, action) => {

    console.log('product reducer', action);

    switch(action.type) {
      case productActions.PRODUCTS_START:
        return { ...state, loading: true, error: null };
      case productActions.PRODUCTS_ERROR:
        return { ...state, loading: false, error: action.payload };
      case productActions.GET_PRODUCTS_SUCCESS:
        return { ...state, loading: false, error: null, products: action.payload }
      case productActions.PRODUCT_VALUE_CHANGE:
        return adaptProductChange(state, action.payload);
      default:
        return state;
    }
};


export default reducer;

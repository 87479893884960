import axios from 'axios';

let baseURL = '__API_ENDPOINT_PLACEHOLDER__'
let awsURL = 'https://r04clp5nyk.execute-api.eu-central-1.amazonaws.com/prod';

if(process.env.NODE_ENV === 'development') {
    baseURL = 'https://uat1.goodmonday.io/api';
    awsURL = 'https://tgz4h90beh.execute-api.eu-central-1.amazonaws.com/test';
}



const instance = axios.create({
    baseURL
});

export const awsAxios = axios.create({
  baseURL: awsURL
})

export default instance;

import React, { Component } from 'react';
import { Heading, Text, Subtitle } from '../../components/UI/CustomTypography';
import '../../style/Registration.css';
import RegistrationRow from '../../components/Registration/RegistrationRow';
import Button from '@material-ui/core/Button';
import Spinner from '../../components/UI/Spinner';
import { connect } from 'react-redux';
import { getProductsByClientName } from '../../store/products/actions';
import { onCreateRegistration, initRegistration } from '../../store/registrations/actions';
import { withStyles } from '@material-ui/core/styles';
import history from '../../shared/history';
import Modal from '../../components/UI/Modal';

const styles = {
  successButton: {
    backgroundColor: '#00ada0',
    marginTop: 20
  },
  text: {
    color: 'white',
  }
};

class RegistrationCreate extends Component {
  state = {
    modalOpened: false
  }
  componentDidMount (){
    const { match: { params: { clientId } } } = this.props;
    this.props.getProductsByClientName(clientId);
    this.props.initRegistration();
  }

  componentWillReceiveProps (nextProps){
    if(nextProps.isCreated && !this.state.modalOpened){
      this.setState({ modalOpened: true })
    }
  }

  render (){
    const { classes,
            client,
            products,
            error,
            loading,
            isProcessing
          } = this.props;
    if(error){
      return (<div style={{ color: 'red' }}>Something went wrong</div>)
    }

    if(loading)
      {return (<Spinner />)}

    const selectedProductsCount = this.selectedProductsCount();

    return(
      <div className='Registration Page'>
        <Modal
          handleOnClose={this.onModalClose}
          headline={'THANK YOU FOR YOUR REGISTRATION!'}
          isOpen={this.state.modalOpened}
          variant='THANK-YOU-MODAL'
        />

        <Modal
          handleOnClose={() => {}}
          headline={'Proccesing...'}
          isOpen={isProcessing}
          variant='THANK-YOU-MODAL'
        />
        <Heading title={`${client.name} REGISTRATIONS`} />
        <div className='Registration-table'>
          <div className='Registration-row heading'>
            <Subtitle className='Registration-item-fill'>PRODUCT</Subtitle>
            <Subtitle className='Registration-item-20pc'>NOTE</Subtitle>
            <Subtitle className='Registration-item-20pc'>AMOUNT</Subtitle>
          </div>
          <div className='Page-Scroll'>
            {
              products.map( item =>
                (<RegistrationRow
                  isCreate
                  item={item}
                  key={item.id}
                />)
              )
            }
          </div>
        </div>
        <div className='Registration-footer'>
          <Text className='Registration-footer-text'>{selectedProductsCount} OUT OF {products.length} PRODUCTS REGISTERED</Text>
          <Button
            className={classes.successButton}
            disabled={selectedProductsCount === 0}
            onClick={this.handleFinishRegistrationClick}
            variant='contained'
          >
          <Subtitle
            className={classes.text}
          >FINISH REGISTRATION</Subtitle>
          </Button>
        </div>
      </div>
    )


  }

  onModalClose = () => {
    history.push('/');
  }

  handleFinishRegistrationClick = () => {
    const { products } = this.props;
    if(this.selectedProductsCount() === 0) {return;}
    this.props.onCreateRegistration(products);
  }

  selectedProductsCount = () => {
    const { products } = this.props;
    const itemsSelected = products.reduce( (total, product) =>
                              product.amount && product.amount !== " " && Number(product.amount.replace(',', '.')) >= 0?
                                total + 1: total, 0);

    return itemsSelected;
  }


}

const mapStateToProps = (state) => (
  {
    products: state.products.products,
    loading: state.products.loading || state.clients.loading,
    error: state.products.error || state.clients.error,
    client: state.clients.client,
    isCreated: state.registrations.isCreated,
    isProcessing: state.registrations.loading
  }
)


export default connect(mapStateToProps, { getProductsByClientName, onCreateRegistration, initRegistration })(withStyles(styles)(RegistrationCreate));

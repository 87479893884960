import axios, { awsAxios } from './axios';

class Api {
    authenticate = (accessToken) => {
      this.accessToken = accessToken;
    }

    _authHeader = () => {
      return { headers: {'Authorization': this.accessToken} }
    }

    clients = {
      getAll: (search) => {
        let searchQuery = search ? `&name[$iLike]=${search}%` : '';
        
        return axios.get(
        '/clients?$limit=100&$select[]=name&$select[]=id&$select[]=address&$select[]=city&$select[]=zipcode&$sort[name]=1&active=true' + searchQuery,
        this._authHeader()
      )},
      getById: clientId => axios.get(
        `/clients/${clientId}?$select[]=name`,
        this._authHeader()
      )
    }

    products = {
      getProductsByClientName: (clientName) => awsAxios.get(
        `/inventory_items/${encodeURIComponent(clientName)}`,
        this._authHeader()
      )
    }

    registrations = {
      createRegistration: (products) => awsAxios.post(
        '/inventory_registrations',
        products,
        this._authHeader()
      )
    }
}

const api = new Api();

export default api;
